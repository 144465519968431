import { closestRequired, qsaOptional, targetOptional, targetRequired } from '@/scripts/functions'
import { ProductModal } from '@/scripts/product/product-modal'
import { BlockDeselectEvent, BlockSelectEvent } from '@/scripts/types/events'
import { SlideshowComponent } from '@/scripts/media/sliders'
import { enableZoomOnHover } from '@/scripts/media/magnify'
import { KlaviyoPopup, NotifyMe } from '@/scripts/content/modal'

export function hideProductModal() {
	const productModals = qsaOptional<ProductModal>('product-modal[open]')
	if (!productModals) return
	productModals.forEach((modal) => modal.hide())
}

export function initializeThemeEditor() {
	// ensure selected slide is visible in theme editor
	document.addEventListener('shopify:block:select', function (event: BlockSelectEvent) {
		hideProductModal()
		const target = targetOptional(event)
		if (!target) return
		const blockSelectedIsSlide = target.hasAttribute('data-uc-slideshow-slide')

		if (target instanceof KlaviyoPopup || target instanceof NotifyMe) {
			target.show()
		} else if (blockSelectedIsSlide) {
			const parentSlideshowComponent = closestRequired<SlideshowComponent>(
				target,
				'slideshow-component'
			)
			parentSlideshowComponent.pause()

			setTimeout(function () {
				parentSlideshowComponent.slider.scrollTo({
					left: target.offsetLeft,
				})
			}, 200)
		}
	})

	document.addEventListener('shopify:block:deselect', function (event: BlockDeselectEvent) {
		const target = targetRequired(event)
		const blockDeselectedIsSlide = target.hasAttribute('data-uc-slideshow-slide')
		if (target instanceof KlaviyoPopup || target instanceof NotifyMe) {
			target.hide()
		} else if (blockDeselectedIsSlide) {
			const parentSlideshowComponent = closestRequired<SlideshowComponent>(
				target,
				'slideshow-component'
			)
			if (parentSlideshowComponent.autoplayButtonIsSetToPlay) parentSlideshowComponent.play()
		}
	})

	document.addEventListener('shopify:section:load', () => {
		hideProductModal()
		enableZoomOnHover(2)
	})

	document.addEventListener('shopify:section:reorder', () => hideProductModal())

	document.addEventListener('shopify:section:select', () => hideProductModal())

	document.addEventListener('shopify:section:deselect', () => hideProductModal())

	document.addEventListener('shopify:inspector:activate', () => hideProductModal())

	document.addEventListener('shopify:inspector:deactivate', () => hideProductModal())
}
