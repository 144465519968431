import { currentTargetRequired, qsRequired, relatedTargetOptional } from '@/scripts/functions'
import { UcoastEl } from '@/scripts/core/UcoastEl'

export class SelectDisclosure extends UcoastEl {
	static htmlSelector = 'select-disclosure'
	elements: {
		input: HTMLInputElement | HTMLSelectElement
		button: HTMLButtonElement
		panel: HTMLElement
	}
	suppressSubmit: boolean
	constructor() {
		super()
		this.elements = this.getElements()
		this.suppressSubmit = this.hasAttribute('data-uc-supress-submit')
	}

	getElements() {
		return {
			input: qsRequired<HTMLInputElement | HTMLSelectElement>('[data-uc-select-value]', this),
			button: qsRequired<HTMLButtonElement>('button', this),
			panel: qsRequired('[data-uc-disclosure-list-wrapper]', this),
		}
	}

	override connectedCallback() {
		super.connectedCallback()
		this.onConnectedCallback()
	}

	onConnectedCallback() {
		this.elements = this.getElements()
		this.elements.button.addEventListener('click', this.openSelector.bind(this))
		this.elements.button.addEventListener('focusout', this.closeSelector.bind(this))
		this.addEventListener('keyup', this.onContainerKeyUp.bind(this))

		this.querySelectorAll('a').forEach((item) =>
			item.addEventListener('click', this.onItemClick.bind(this))
		)
	}

	hidePanel() {
		this.elements.button.setAttribute('aria-expanded', 'false')
		this.elements.panel.setAttribute('hidden', '')
		this.removeAttribute('data-uc-open')
	}

	onContainerKeyUp(event: KeyboardEvent) {
		if (event.code.toUpperCase() !== 'ESCAPE') return
		this.hidePanel()
		this.elements.button.focus()
	}

	onItemClick(event: MouseEvent) {
		event.preventDefault()
		const form = qsRequired<HTMLFormElement>('form', this)
		const currentTarget = currentTargetRequired(event)
		this.elements.input.value = currentTarget.dataset.value ?? ''
		form.dispatchEvent(new Event('input', { bubbles: true }))
		if (this.suppressSubmit) {
			const selectText = qsRequired('[data-uc-select-text]', this)
			selectText.textContent = currentTarget.textContent
			this.closeSelector(event)
		} else {
			form.submit()
		}
	}

	openSelector() {
		this.setAttribute('data-uc-open', '')
		this.elements.button.focus()
		this.elements.panel.toggleAttribute('hidden')
		this.elements.button.setAttribute(
			'aria-expanded',
			(this.elements.button.getAttribute('aria-expanded') === 'false').toString()
		)
	}

	closeSelector(event: MouseEvent) {
		const relatedTarget = relatedTargetOptional(event)
		if (!relatedTarget) {
			this.hidePanel()
			return
		}
		if (
			this.elements.panel.contains(relatedTarget) ||
			this.elements.button.contains(relatedTarget)
		)
			return
		this.hidePanel()
	}
}

export class SelectDisclosureWithoutForm extends SelectDisclosure {
	static override htmlSelector = 'select-disclosure-without-form'
	constructor() {
		super()
	}
	override openSelector() {
		if (this.hasAttribute('data-uc-disabled')) return
		this.setAttribute('data-uc-open', '')
		this.elements.button.focus()
		this.elements.panel.toggleAttribute('hidden')
		this.elements.button.setAttribute(
			'aria-expanded',
			(this.elements.button.getAttribute('aria-expanded') === 'false').toString()
		)
	}
	override onItemClick(event: MouseEvent) {
		event.preventDefault()
		const currentTarget = currentTargetRequired(event)
		this.elements.input.value = currentTarget.dataset.value ?? ''
		const selectText = qsRequired('[data-uc-select-text]', this)
		selectText.innerHTML = currentTarget.innerHTML
		this.closeSelector(event)
		this.elements.input.dispatchEvent(new Event('change', { bubbles: true }))
	}
}
